import React from "react"

import SuccessPage from "../components/SuccessPage"

const ThankYouPage = () => (
  <SuccessPage 
       title="Thank You!"
    subtitle="Your email has been added to our mailing list"
  />
)

export default ThankYouPage
