import React from "react"
import { graphql, useStaticQuery } from "gatsby"


// Elements
import Layout from "../elements/layout"
import SEO from "../elements/seo"
import Slider from "../elements/slider"

export default function SuccessPage({title, subtitle}) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "sliderImage11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Thank You" />
      <Slider
        sliderImage={data.file.childImageSharp.fluid}
        sliderTitle={title}
        sliderSubtitle={subtitle}
      />
    </Layout>
  )
}
